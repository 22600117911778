import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import ClientSearch from "../ClientSearch";
import ReactHtmlParser from "react-html-parser";

const Header = ({ category, allPosts, engine, domain }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{domain?.name}</title>
        {ReactHtmlParser(domain?.header_script)}
        <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
        <script>
          {`
            !function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");
          `}
        </script>
      </Helmet>
      <header className="header-two">
        <div className="container is-max-widescreen">
          <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <Link to={`/`} className="navbar-item">
                <img src={domain?.logo} alt={domain?.name} />
              </Link>
              <a
                role="button"
                className="navbar-burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div className="navbar-menu">
              <div className="navbar-end">
                <Link to={`/`} className="navbar-item">
                  Home
                </Link>
                {category?.map((categoryData) => {
                  return (
                    <Link to={`/${categoryData.slug}`} className="navbar-item">
                      {categoryData?.name}
                    </Link>
                  );
                })}
                <ClientSearch allPosts={allPosts} engine={engine} />
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
export default Header;
